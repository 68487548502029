import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

require('./bootstrap');
const moment = require('moment');
let tInstance = null;

window.addEventListener('load', function () {
    tInstance = tippy('[data-tippy-content]');

    console.log(tInstance);

    const questions = {
        1: {
            start: "01:46",
            duration: "1000"
        },
        2: {
            start: "02:08",
            duration: "1000"
        }
    }

    const video = document.getElementById('video');
    if (video !== null) {
        const videoDurationDiv = document.getElementById('videoDuration');
        const videoProgressionDiv = document.getElementById('videoProgression');
        const videoPlayButton = document.getElementById('videoPlayButton');
        const videoPauseButton = document.getElementById('videoPauseButton');
        const videoOverlay = document.getElementById('videoOverlay');
        const addPointButton = document.getElementById('addPoint');
        const addPointAudio = document.getElementById('addPointAudio');
        const submitQuestionButton = document.getElementById('submitQuestion');

        video.addEventListener('loadedmetadata', function (event) {
            videoDurationDiv.innerText = moment.unix(event.target.duration).format('mm:ss');
        });

        video.addEventListener('timeupdate', function (event) {
            const currentTime = moment.unix(event.target.currentTime).format('mm:ss');
            videoProgressionDiv.innerText = currentTime;

            for (const [key, question] of Object.entries(questions)) {
                if (question.start === currentTime) {
                    pauseVideo();
                    videoOverlay.classList.remove('hidden');
                    break;
                }
            }
        });

        videoPlayButton.addEventListener('click', function () {
            playVideo();
        });

        videoPauseButton.addEventListener('click', function () {
            pauseVideo();
        });

        addPointButton.addEventListener('click', function () {
            addPoint();
        });

        submitQuestionButton.addEventListener('click', function () {
            submitQuestion();
        });
    }
})

function playVideo() {
    video.play();

    videoPlayButton.classList.add('hidden');
    videoPauseButton.classList.remove('hidden');
}

function pauseVideo() {
    video.pause();

    videoPauseButton.classList.add('hidden');
    videoPlayButton.classList.remove('hidden');
}

function addPoint() {
    addPointAudio.pause();
    addPointAudio.currentTime = 0;
    addPointAudio.play();
}

function submitQuestion() {
    video.currentTime = video.currentTime + 1;
    playVideo();
    videoOverlay.classList.add('hidden');
}

window.addEventListener('reloadTippy', event => {
    tInstance.forEach(function (x) {
        x.destroy();
    });

    setTimeout(function () {
        tInstance = tippy('[data-tippy-content]');
    }, 500);
})
